import React, { useEffect, useState } from "react"
import { SizeInfo } from "./orderForm"

type Props = {
  nextStep: (sizeInfo: SizeInfo["shoesInfo"][0]) => void
  orderGender?: "m" | "f"
  orderSize?: number
  shoesCount: number
  shoeNumber: number
}

const AVALIABLE_SIZES = [
  5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13,
]
const nextButtonClasses =
  "text-md mx-auto w-[160px] rounded-full bg-green py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out md:text-[14px] lg:w-[220px]"
const selectorBaseClasses =
  "rounded flex justify-center items-center text-[14px] bg-white/60 hover:text-green text-white p-6"

const FormSizeInfo: React.FC<Props> = ({
  nextStep,
  orderGender,
  orderSize,
  shoesCount,
  shoeNumber,
}) => {
  const [gender, setGender] = useState(orderGender ?? "m")
  const [selectedSize, setSelectedSize] = useState(
    orderSize ?? AVALIABLE_SIZES[0]
  )

  useEffect(() => {
    setGender(orderGender ?? "m")
    setSelectedSize(orderSize ?? AVALIABLE_SIZES[0])
  }, [orderGender, orderSize, shoeNumber])

  return (
    <div className="relative flex h-full w-full flex-col gap-4 ">
      <div className="flex flex-col">
        <span className="font-sans text-[10px] md:text-[12px]">
          Please select
          {shoesCount > 1 && (
            <span>
              {" "}
              for pair ({shoeNumber + 1}/{shoesCount})
            </span>
          )}
          :{" "}
        </span>
        <span className="text-[14px] uppercase text-green">Gender</span>
      </div>
      <div className="flex flex-row gap-4">
        <button
          onClick={() => setGender("f")}
          className={`${selectorBaseClasses} h-[40px] w-[100px] ${
            gender === "f" ? "!hover:text-black !bg-green !text-black" : ""
          } `}
        >
          Women
        </button>
        <button
          onClick={() => setGender("m")}
          className={`${selectorBaseClasses} h-[40px] w-[100px] ${
            gender === "m" ? "!hover:text-black !bg-green !text-black" : ""
          } `}
        >
          Men
        </button>
      </div>

      <span className="text-[14px] uppercase text-green">Shoe Size (US)</span>
      <div className="flex flex-row flex-wrap gap-4">
        {AVALIABLE_SIZES.map(size => (
          <button
            key={size}
            onClick={() => setSelectedSize(size)}
            className={`${selectorBaseClasses} h-[30px] w-[40px] md:h-[40px] md:w-[60px] ${
              selectedSize === size
                ? "!hover:text-black !bg-green !text-black"
                : ""
            } `}
          >
            {size}
          </button>
        ))}
      </div>

      <button
        onClick={() => {
          nextStep({ gender, size: selectedSize })
        }}
        className={nextButtonClasses}
      >
        Next
      </button>
    </div>
  )
}

export default FormSizeInfo
