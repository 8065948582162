import React, { useEffect, useState } from "react"
import CrossIcon from "../../images/svg/cross.svg"
import { graphql, useStaticQuery } from "gatsby"

type Props = {
  nextStep: (bundle?: Bundle) => void
  resetSteps: () => void
}

export type Bundle = {
  id: number
  protected: boolean
  tag: string
  title: string
  subtitle: string
  content: string[]
  price: { amount: number; currency: "eur" | "ada" }
  extraInfo: string
  projectUid: string
  shoesCount: number
}

const CURRENCIES = {
  EUR: "eur",
  ADA: "ada",
}

const setCurrencySymbol = (currency?: string) => {
  switch (currency) {
    case CURRENCIES.ADA:
      return "ADA"
    case CURRENCIES.EUR:
      return "€"
    default:
      return ""
  }
}

const showPriceExtraInfo = (currency?: string) => {
  return currency === CURRENCIES.EUR
}

const fetchBundles = async (baseUrl: string) => {
  const res = await fetch(`${baseUrl}/bundles`, {
    method: "get",
  }).then(res => {
    if (res.ok) return res.json()

    return Promise.reject(res)
  })
  return res.data
}

const unlockBundle = async (baseUrl: string, id: number, password: string) => {
  const res = await fetch(`${baseUrl}/bundles/unlock/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "put",
    body: JSON.stringify({ password }),
  }).then(res => {
    if (res.ok) return res.json()

    return Promise.reject(res)
  })

  return res.data
}

const inputClasses = "rounded-lg bg-white/60 border-none font-sans"

const BundleSelector: React.FC<Props> = ({ nextStep, resetSteps }) => {
  const [bundles, setBundles] = useState<Bundle[]>([])
  const [selectedBundle, setSelectedBundle] = useState<Bundle | undefined>(
    undefined
  )
  const [bundlePassword, setBundlePassword] = useState("")
  const [unlockError, setUnlockError] = useState("")

  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            backendUrl
          }
        }
      }
    `
  )

  useEffect(() => {
    if (site.siteMetadata.backendUrl) {
      ;(async () => {
        const localBundles = localStorage.getItem("bundles")

        if (!localBundles) {
          try {
            const apiBundles = await fetchBundles(site.siteMetadata.backendUrl)
            localStorage.setItem("bundles", JSON.stringify(apiBundles))
            setBundles(apiBundles)
            apiBundles.length && setSelectedBundle(apiBundles[0])
          } catch (e) {
            setUnlockError("Could not fetch bundles")
          }
        } else {
          setBundles(JSON.parse(localBundles))
          setSelectedBundle(JSON.parse(localBundles)[0])
        }
      })()
    }
  }, [site.siteMetadata.backendUrl])

  useEffect(() => {
    setUnlockError("")
  }, [bundlePassword])

  const unlockBundleHandler = async () => {
    if (!selectedBundle?.id) {
      return
    }
    setUnlockError("")

    let unlockedBundle: Bundle
    try {
      unlockedBundle = await unlockBundle(
        site.siteMetadata.backendUrl,
        selectedBundle?.id,
        bundlePassword
      )
      if (unlockedBundle) {
        setSelectedBundle(unlockedBundle)
        const updatedBundles = bundles.map((bundle: any) => {
          return bundle.id === unlockedBundle.id ? unlockedBundle : bundle
        })
        setBundles(updatedBundles)
        localStorage.setItem("bundles", JSON.stringify(updatedBundles))
      }
    } catch (e) {
      setUnlockError("Wrong Password")
    }
  }

  return (
    <div className="flex h-full w-full flex-row items-center justify-center">
      <div className="flex min-h-[400px] w-full flex-col items-center justify-center gap-4 md:flex-row md:items-start">
        {bundles.length && (
          <div className="flex w-fit flex-col gap-4 rounded-[10px] bg-white/50 p-4 pr-8 text-left font-gravity-wide text-[10px] backdrop-blur-lg">
            {bundles.map((bundle, index) => (
              <div
                key={bundle.id}
                className="flex cursor-pointer flex-row items-center"
              >
                <input
                  id={String(bundle.id)}
                  onChange={e =>
                    setSelectedBundle(
                      bundles.find(
                        bundle => String(bundle.id) === e.target.value
                      )
                    )
                  }
                  defaultChecked={index === 0}
                  type="radio"
                  name="bundle"
                  value={bundle.id}
                  className="form-radio h-6 w-6 cursor-pointer border border-green bg-transparent text-green"
                />
                <label
                  htmlFor={String(bundle.id)}
                  className="inline-flex cursor-pointer flex-col pl-4 uppercase"
                >
                  <span className="font-black leading-tight md:text-[14px]">
                    Bundle {bundle.id}
                  </span>
                  <span className="font-sans md:text-[12px]">{bundle.tag}</span>
                </label>
              </div>
            ))}
            <button
              onClick={() => {
                selectedBundle?.protected
                  ? unlockBundleHandler()
                  : nextStep(selectedBundle)
              }}
              className="text-md mx-auto w-[160px] rounded-full bg-green py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out md:text-[14px] lg:w-[220px]"
            >
              {selectedBundle?.protected ? "Unlock" : "Next"}
            </button>
          </div>
        )}
        {selectedBundle && (
          <div className="relative flex w-[280px] flex-col gap-6 rounded-[10px] bg-white/50 p-4 pr-8 text-left font-sans backdrop-blur-lg">
            <button
              className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
              onClick={resetSteps}
            >
              <CrossIcon className="h-4 w-4 text-white" />
            </button>
            <div className=":md-text-[14px] flex flex-col gap-4 text-start font-sans text-[10px]">
              <span className="pr-2 font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                {selectedBundle?.title}
              </span>
              {selectedBundle?.protected && (
                <input
                  className={inputClasses}
                  type="password"
                  placeholder="Password"
                  onChange={e => setBundlePassword(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === "Enter") unlockBundleHandler()
                  }}
                />
              )}
              {unlockError && (
                <span className="pr-2 font-gravity-wide text-[10px] font-black uppercase leading-tight text-red-600 md:text-[14px]">
                  {unlockError}
                </span>
              )}
              {!selectedBundle?.protected && (
                <div className="flex flex-col gap-2 text-[12px] md:text-[14px]">
                  <span className="italic">{selectedBundle?.subtitle}</span>
                  <div>
                    <ol className="li:pl-1 list-inside list-disc pl-2 font-sans">
                      {selectedBundle?.content.map((text, i) => (
                        <li key={`text_${i}`}> {text} </li>
                      ))}
                    </ol>
                  </div>
                  <div>
                    <span className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                      Price: <br />
                      {selectedBundle?.price.amount}{" "}
                      {setCurrencySymbol(selectedBundle?.price.currency)}
                    </span>
                    {showPriceExtraInfo(selectedBundle?.price.currency) && (
                      <span className="block italic">
                        (ADA price at equivalent value)
                      </span>
                    )}
                    {selectedBundle?.extraInfo && (
                      <span className="text-right text-[8px] italic lg:text-[10px]">
                        {selectedBundle.extraInfo}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default BundleSelector
