import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PDP from "../components/bone-dragon/pdp"
import Animation from "../components/bone-dragon/animation"
import { Transition } from "@headlessui/react"
import BoneDragonLoop from "../videos/bone-dragon/bone-dragon-loop.mp4"

const BoneDragon: React.FC = () => {
  const [skipIntro, setSkipIntro] = useState(false)

  const handleSkipIntro = () => setSkipIntro(true)
  useEffect(() => {
    // cleanup the bundles on mount
    localStorage.removeItem("bundles")
  }, [])

  return (
    <Layout bgColor="black">
      <SEO title="Bone Dragon" />

      <div className="relative flex h-screen flex-col items-center justify-center">
        <Transition
          show={!skipIntro}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 z-10 flex h-full w-full flex-col items-center justify-center">
            <Animation onSkipIntro={handleSkipIntro} />
          </div>
        </Transition>
        {skipIntro && (
          <div
            className={`absolute top-1/2 left-1/2 -z-[1] flex w-full -translate-x-1/2 -translate-y-1/2 flex-row justify-center ${
              !skipIntro ? "invisible" : ""
            }
          `}
          >
            <video
              src={BoneDragonLoop}
              playsInline
              autoPlay
              muted
              loop
              controls={false}
              className="max-h-[100vh] object-contain"
            />
          </div>
        )}
        <div
          className={`absolute inset-0 flex h-full w-full flex-col items-center justify-center ${
            !skipIntro ? "invisible" : ""
          }`}
        >
          <PDP />
        </div>
      </div>
    </Layout>
  )
}

export default BoneDragon
