import React, { useState } from "react"
import CrossIcon from "../../images/svg/cross.svg"
import { graphql, useStaticQuery } from "gatsby"
import EventCountdown from "../EventCountdown"

type Props = {
  nextStep: () => void
}

const defaultButtonClasses =
  "text-md mx-auto h-fit w-fit rounded-full bg-white hover:bg-green py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:text-black focus:text-black lg:min-w-[220px]"

const saleFinishedButtonClasses =
  "text-md mx-auto h-fit w-fit rounded-full bg-red py-2 px-4 uppercase leading-normal text-white transition-colors duration-200 ease-in-out hover:text-white focus:text-white lg:min-w-[220px]"

const DefaultView: React.FC<Props> = ({ nextStep }) => {
  const [showDetails, setShowDetails] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(false)

  const handleToggleDetails = () => {
    setShowDetails(!showDetails)
    setShowDisclaimer(false)
  }
  const handleToggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer)
    setShowDetails(false)
  }

  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            boneDragon {
              eventStartsAt
              eventEndsAt
              boneDragonUrl
            }
          }
        }
      }
    `
  )

  return (
    <div className="flex h-full w-full flex-col items-center justify-end gap-4 pb-4 text-center font-gravity-wide text-[12px] uppercase sm:w-auto lg:gap-12 lg:text-[14px]">
      <div className="flex flex-1 flex-row items-center justify-center gap-4">
        <EventCountdown
          cta={() => nextStep()}
          eventEndedText="PRE-SALE ENDED"
          eventEndsAt={site.siteMetadata.boneDragon.eventEndsAt}
          eventStartsAt={site.siteMetadata.boneDragon.eventStartsAt}
          buttonClasses={saleFinishedButtonClasses}
        >
          <span>Select Bundle</span>
        </EventCountdown>
        <button
          onClick={() =>
            window.open(site.siteMetadata.boneDragon.boneDragonUrl)
          }
          className={defaultButtonClasses}
        >
          SHOES STILL AVAILABLE {">"} HERE
        </button>
      </div>
      <div className="relative flex w-full flex-row justify-center gap-4 lg:gap-6">
        <div className="absolute bottom-[50px] left-1/2 flex w-full max-w-[90%] -translate-x-1/2 justify-center text-[10px] font-normal normal-case md:max-w-[900px] md:text-[14px]">
          {showDetails && (
            <div className="pointer-events-auto relative flex w-full flex-col gap-6 rounded-[10px] bg-white/50 p-4 pr-8 text-left font-sans backdrop-blur-lg transition-opacity duration-150 ease-in-out">
              <button
                className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
                onClick={handleToggleDetails}
              >
                <CrossIcon className="h-4 w-4 text-white" />
              </button>
              <h3 className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                Details
              </h3>
              <div className="grid grid-cols-2 gap-4 font-sans text-[8px] lg:text-[12px]">
                <div className="col-span-1 flex flex-col gap-6">
                  <div className="flex flex-col gap-2">
                    <h3 className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                      Bone dragon - seed one - digital twin
                    </h3>
                    <div>
                      <span className="pl-2 font-gravity-wide">Utility:</span>
                      <ol className="li:pl-2 list-inside list-disc pl-4 font-sans">
                        <li>
                          KEY USE CASE IN EXCLUSIVE, FUTURE OG DROP (NON
                          STACKABLE)
                        </li>
                        <li>
                          FASHION “STREAK” - BUYERS OF THIS NFT WILL RECEIVE AN
                          EVOLVING DISCOUNT ON FASHION ITEMS
                        </li>
                        <li>OREMOB “SNEAKER HEAD” PERKS</li>
                      </ol>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <h3 className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                      BALACLAVA - DIGITAL TWIN
                    </h3>
                    <div>
                      <span className="pl-2 font-gravity-wide">Utility:</span>
                      <ol className="li:pl-2 list-inside list-disc pl-4 font-sans">
                        <li>
                          KEY USE CASE IN EXCLUSIVE, FUTURE OG DROP (NON
                          STACKABLE)
                        </li>
                        <li>
                          A 1 OF 1 BALACLAVA ARTWORK WILL BE DRAWN BY ORE &
                          RAFFLED TO A HOLDER OF THIS NFT
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <h3 className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                      BONE DRAGON CASEY NFT
                    </h3>
                    <div>
                      <span className="pl-2 font-gravity-wide">Utility:</span>
                      <ol className="li:pl-2 list-inside list-disc pl-4 font-sans">
                        <li>
                          FUTURE BONE DRAGON PFP CASING WITH EXCLUSIVE GAMIFIED
                          BENEFITS
                        </li>
                        <li>1+ UNREVEALED UTILITIES</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 flex flex-col gap-6">
                  <div className="flex flex-col gap-2">
                    <h3 className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                      DRAGON CHARM RING NFT
                    </h3>
                    <div>
                      <span className="pl-2 font-gravity-wide">Utility:</span>
                      <ol className="li:pl-2 list-inside list-disc pl-4 font-sans">
                        <li>
                          STACKABLE TOWARDS ONCE OFF OG WL, BY HOLDING 8+ RINGS
                          (NOT VIABLE FOR THE IMMINENT OG DROP, AS WL IS
                          ALLOCATED ALREADY)
                        </li>
                        <li>1 + UNREVEALED UTILITIES</li>
                      </ol>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <h3 className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                      FFS SHOE ANIMATION NFT
                    </h3>
                    <div>
                      <span className="pl-2 font-gravity-wide">Utility:</span>
                      <ol className="li:pl-2 list-inside list-disc pl-4 font-sans">
                        <li>TO BE REVEALED BY FLOWERS FOR SOCIETY</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showDisclaimer && (
            <div className="relative flex flex-col gap-[16px] rounded-[10px] bg-white/50 p-4 pr-8 text-left font-sans backdrop-blur-lg transition-opacity duration-150 ease-in-out">
              <button
                className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
                onClick={handleToggleDisclaimer}
              >
                <CrossIcon className="h-4 w-4 text-white" />
              </button>
              <h3 className="font-gravity-wide text-[1.2em] font-black uppercase leading-none tracking-wide">
                Disclaimer:
              </h3>
              <ol className="list-disc pl-4 uppercase leading-tight">
                <li> Sale available to public </li>
                <li> KYC DETAILS REQUIRED FOR SHIPPING </li>
                <li>PHYSICAL ASSETS ARE LIMITED & SUBJECT TO AVAILABILITY</li>
                <li>CREATED IN COLLABORATION WITH “FLOWERS FOR SOCIETY”</li>
                <li>
                  BASE PRICE €219.95 FOR ANY REMAINING STOCK AFTER PRE-SALES
                </li>
                <li>“BPM” BUNDLE IS EXCLUSIVE & NFT/PASSWORD GATED</li>
                <li>NO GUARANTEE OF SHOE BEING AVAILABLE IN YOUR SIZE</li>
                <li>SALE OPEN FOR 48HRS</li>
                <li>YOU MAY PURCHASE MULTIPLE BUNDLES</li>
                <li>NO WALLET LIMIT ON PURCHASES</li>
              </ol>
            </div>
          )}
        </div>
        <button
          className={`flex w-[160px] items-center justify-center justify-self-end rounded-full py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:bg-green hover:text-black focus:text-black lg:w-[220px] ${
            showDetails ? "bg-green" : "bg-white"
          }`}
          onClick={handleToggleDetails}
        >
          Details
        </button>
        <button
          className={`flex w-[160px] items-center justify-center rounded-full py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:bg-green hover:text-black focus:text-black lg:w-[220px] ${
            showDisclaimer ? "bg-green" : "bg-white"
          }`}
          onClick={handleToggleDisclaimer}
        >
          Disclaimer
        </button>
      </div>
    </div>
  )
}

export default DefaultView
