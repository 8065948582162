import React, { useState } from "react"
import { AdditionalInfo } from "./orderForm"

type Props = {
  nextStep: (additionalInfo: AdditionalInfo) => void
  orderAdditionalInfo?: string
}

const inputClasses = "rounded-lg bg-white/60 border-none font-sans"
const nextButtonClasses =
  "text-md mx-auto w-[160px] rounded-full bg-green py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out md:text-[14px] lg:w-[220px]"

const FormAdditionalInfo: React.FC<Props> = ({
  nextStep,
  orderAdditionalInfo,
}) => {
  const [additionalInfo, setAdditionalInfo] = useState(orderAdditionalInfo)

  return (
    <div className="relative flex h-full w-full flex-col gap-4">
      <div className="flex flex-col">
        <span className="font-sans text-[10px] md:text-[12px]">
          Please fill out:{" "}
        </span>
        <span className="text-[14px] uppercase text-green">
          Additional Information
        </span>
      </div>
      <textarea
        className={inputClasses}
        defaultValue={additionalInfo}
        rows={5}
        placeholder="Additional Information (Optional)"
        onChange={e => setAdditionalInfo(e.target.value)}
      />

      <button
        className={nextButtonClasses}
        onClick={() => {
          nextStep({ additionalInfo })
        }}
      >
        Check Out
      </button>
    </div>
  )
}

export default FormAdditionalInfo
