import React, { useEffect, useState } from "react"
import DefaultView from "./defaultView"
import BundleSelector, { Bundle } from "./bundleSelector"
import OrderForm, { Order } from "./orderForm"
import Confirmation from "./confirmation"
import TitleLogo from "../../images/svg/bone-dragon/zero-one.svg"
import { graphql, useStaticQuery } from "gatsby"

export const steps = [
  "DEFAULT",
  "BUNDLE_SELECT",
  "ORDER_FORM_SIZE",
  "ORDER_FORM_CONTACT",
  "ORDER_FORM_ADDRESS",
  "ORDER_FORM_ADDITIONAL",
  "CONFIRM",
]

const DEFAULT_STEPS = {
  DEFAULT: 0,
  BUNDLE_SELECT: 1,
  ORDER_FORM_SIZE: 2,
  ORDER_FORM_CONTACT: 3,
  ORDER_FORM_ADDRESS: 4,
  ORDER_FORM_ADDITIONAL: 5,
  CONFIRM: 6,
}

const constructViewSteps = (numShoes: number) => {
  const stepArray = steps
    .map(step => {
      if (step === "ORDER_FORM_SIZE") {
        const bundleSteps = []
        for (let i = 0; i < numShoes; i++) {
          bundleSteps.push(
            i === 0 ? "ORDER_FORM_SIZE" : `ORDER_FORM_SIZE_${i + 1}`
          )
        }
        return bundleSteps
      }
      return step
    })
    .flat()

  return stepArray.reduce<{ [key: string]: number }>(
    (viewSteps, step, index) => {
      viewSteps[step] = index
      return viewSteps
    },
    {}
  )
}

const backButtonAllowedSteps = [
  "BUNDLE_SELECT",
  "ORDER_FORM_SIZE",
  "ORDER_FORM_CONTACT",
  "ORDER_FORM_ADDRESS",
  "ORDER_FORM_ADDITIONAL",
]

const shouldShowBackButton = (
  steps: { [key: string]: number },
  currentStep: number
) => {
  const currentStepName = Object.keys(steps).find(
    key => steps[key] === currentStep
  )
  return backButtonAllowedSteps.some(step => currentStepName?.includes(step))
}

const PDP: React.FC = () => {
  const [viewSteps, setViewSteps] =
    useState<{ [key: string]: number }>(DEFAULT_STEPS)

  const [viewStep, setViewStep] = useState(0)
  const [showBackButton, setShowBackButton] = useState(false)
  const [nmkrUrl, setNmkrUrl] = useState("")
  const [selectedBundle, setSelectedBundle] = useState<Bundle>()

  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            backendUrl
          }
        }
      }
    `
  )

  const sendOrder = async (order: Order) => {
    const orderBody = {
      bundleId: selectedBundle?.id,
      orderInfo: order,
    }
    const res = await fetch(`${site.siteMetadata.backendUrl}/orders`, {
      method: "POST",
      body: JSON.stringify(orderBody),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(res => {
      if (res.ok) return res.json()

      return Promise.reject(res)
    })

    setNmkrUrl(res.data.nmkrUrl)
    setViewStep(viewSteps.CONFIRM)
  }

  useEffect(() => {
    if (selectedBundle) {
      setViewSteps(constructViewSteps(selectedBundle.shoesCount))
    }
  }, [selectedBundle])

  useEffect(() => {
    setShowBackButton(shouldShowBackButton(viewSteps, viewStep))
  }, [viewSteps, viewStep])

  return (
    <section className="relative flex h-full w-full flex-col">
      <h1 className="mt-[66px] mb-4 flex w-full flex-col items-center justify-center text-center font-gravity-wide text-[16px] font-black uppercase leading-[1.2] md:text-[40px] lg:text-[2.65vw]">
        <span>Oremob X flowers for society</span>
        <span className="flex items-center gap-4">
          Seed{" "}
          <TitleLogo className="h-[50px] w-[50px] md:h-[80px] md:w-[80px]" />:
          Bone Dragon
        </span>
      </h1>
      <div className="relative flex-1">
        {viewStep === viewSteps.DEFAULT && (
          <DefaultView
            nextStep={() => {
              setViewStep(viewSteps.BUNDLE_SELECT)
            }}
          />
        )}
        {viewStep === viewSteps.BUNDLE_SELECT && (
          <BundleSelector
            nextStep={bundle => {
              setViewStep(viewSteps.ORDER_FORM_SIZE)
              setSelectedBundle(bundle)
            }}
            resetSteps={() => setViewStep(viewSteps.DEFAULT)}
          />
        )}
        {viewStep >= viewSteps.ORDER_FORM_SIZE &&
          viewStep <= viewSteps.ORDER_FORM_ADDITIONAL &&
          selectedBundle && (
            <OrderForm
              nextStep={sendOrder}
              resetSteps={() => setViewStep(viewSteps.DEFAULT)}
              viewStep={viewStep}
              viewSteps={viewSteps}
              setViewStep={setViewStep}
              selectedBundle={selectedBundle}
            />
          )}
        {viewStep === viewSteps.CONFIRM && (
          <Confirmation
            nmkrUrl={nmkrUrl}
            resetSteps={() => setViewStep(viewSteps.DEFAULT)}
          />
        )}
      </div>
      {showBackButton && (
        <div className="flex w-full justify-center pb-4 text-[12px] lg:text-[14px]">
          <button
            onClick={() => {
              setViewStep(viewStep - 1)
            }}
            className="text-md w-[160px] rounded-full bg-green py-2 px-4 text-center font-gravity-wide uppercase leading-normal text-black lg:w-[220px]"
          >
            Back
          </button>
        </div>
      )}
    </section>
  )
}

export default PDP
