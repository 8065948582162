import React, { ReactNode, useState } from "react"
import Countdown, { CountdownRenderProps } from "react-countdown"

type Props = {
  eventStartsAt: string
  eventEndsAt: string
  children: ReactNode
  cta: () => void
  buttonClasses?: string
  eventEndedStyles?: string
  eventEndedText: string
  onEventStarted?: () => void
  onEventEnded?: () => void
}

const renderCountdown = (e: CountdownRenderProps, showCountdown = true) => {
  const { hours, minutes, seconds, completed } = e

  if (!completed && showCountdown) {
    return (
      <span>
        {hours < 10 ? `0${hours}` : hours}:
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </span>
    )
  }

  return <span />
}

const defaultButtonClasses =
  "text-md mx-auto w-fit rounded-full bg-green py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:text-black focus:text-black lg:min-w-[220px]"

const EventCountdown: React.FC<Props> = ({
  eventStartsAt,
  eventEndsAt,
  children,
  cta,
  buttonClasses = defaultButtonClasses,
  eventEndedStyles = "",
  eventEndedText,
  onEventStarted,
  onEventEnded,
}) => {
  const [eventStarted, setEventStarted] = useState(false)
  const [eventEnded, setEventEnded] = useState(false)

  return !eventStarted ? (
    <span className={`${buttonClasses} `}>
      <Countdown
        date={new Date(eventStartsAt)}
        onMount={({ completed }) => {
          setEventStarted(completed)
        }}
        onComplete={() => {
          setEventStarted(true)
          onEventStarted && onEventStarted()
        }}
        renderer={renderCountdown}
      />
    </span>
  ) : (
    <button
      onClick={() => !eventEnded && cta()}
      className={`${buttonClasses} ${
        eventEnded
          ? `poiner-events-none cursor-default ${eventEndedStyles}`
          : ""
      }`}
    >
      {eventEnded ? eventEndedText : children}
      <Countdown
        date={new Date(eventEndsAt)}
        onMount={({ completed }) => {
          setEventEnded(completed)
        }}
        onComplete={() => {
          setEventEnded(true)
          onEventEnded && onEventEnded()
        }}
        renderer={e => renderCountdown(e, false)}
      />
    </button>
  )
}

export default EventCountdown
