import React, { useEffect, useState } from "react"
import CrossIcon from "../../images/svg/cross.svg"
import FormSizeInfo from "./formSizeInfo"
import FormContactInfo from "./formContactInfo"
import FormAddressInfo from "./formAddressInfo"
import FormAdditionalInfo from "./formAdditionalInfo"
import { Bundle } from "./bundleSelector"

const orderDefault: Order = {
  shoesInfo: [],
  name: "",
  surname: "",
  email: "",
  country: "",
  company: "",
  address1: "",
  address2: "",
  zip: "",
  city: "",
  phoneNumber: "",
  additionalInfo: "",
}

type Props = {
  nextStep: (order: Order) => void
  resetSteps: () => void
  viewStep: number
  viewSteps: { [key: string]: number }
  selectedBundle: Bundle
  setViewStep: (step: number) => void
}

export type SizeInfo = {
  shoesInfo: {
    gender: "f" | "m"
    size: number
  }[]
}

export type ContactInfo = {
  name: string
  surname: string
  email: string
  country: string
  company?: string
}

export type AddressInfo = {
  address1: string
  address2?: string
  zip: string
  city: string
  phoneNumber: string
}

export type AdditionalInfo = {
  additionalInfo?: string
}

export type Order = SizeInfo & ContactInfo & AddressInfo & AdditionalInfo

export const getShoeIndex = (
  viewSteps: Props["viewSteps"],
  currentStep: number
) => {
  const currentStepName = Object.keys(viewSteps).find(
    key => viewSteps[key] === currentStep
  )
  return currentStepName?.includes("ORDER_FORM_SIZE_")
    ? Number(currentStepName.replace("ORDER_FORM_SIZE_", "")) - 1
    : 0
}

const OrderForm: React.FC<Props> = ({
  nextStep,
  resetSteps,
  setViewStep,
  viewStep,
  viewSteps,
  selectedBundle,
}) => {
  const [orderInfo, setOrderInfo] = useState<Order>(
    JSON.parse(JSON.stringify(orderDefault))
  )

  useEffect(() => {
    setOrderInfo(JSON.parse(JSON.stringify(orderDefault)))
  }, [selectedBundle])

  return (
    <div className="flex h-full w-full flex-row items-center justify-center">
      <div className="flex w-full flex-col items-center justify-center gap-4 md:flex-row">
        <div className="mx-2 flex w-fit max-w-[95%] flex-col gap-6 rounded-[10px] bg-white/50 p-4 pr-8 text-left font-gravity-wide text-[10px] backdrop-blur-lg md:max-w-[600px]">
          <button
            className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
            onClick={resetSteps}
          >
            <CrossIcon className="h-4 w-4 text-white" />
          </button>
          {viewStep >= viewSteps.ORDER_FORM_SIZE &&
            viewStep < viewSteps.ORDER_FORM_CONTACT && (
              <FormSizeInfo
                orderGender={
                  orderInfo.shoesInfo[getShoeIndex(viewSteps, viewStep)]?.gender
                }
                orderSize={
                  orderInfo.shoesInfo[getShoeIndex(viewSteps, viewStep)]?.size
                }
                nextStep={sizeInfo => {
                  const shoesInfo = orderInfo.shoesInfo
                  shoesInfo[getShoeIndex(viewSteps, viewStep)] = sizeInfo
                  setOrderInfo({
                    ...orderInfo,
                    shoesInfo,
                  })
                  setViewStep(viewStep + 1)
                }}
                shoeNumber={getShoeIndex(viewSteps, viewStep)}
                shoesCount={selectedBundle.shoesCount}
              />
            )}
          {viewStep === viewSteps.ORDER_FORM_CONTACT && (
            <FormContactInfo
              orderName={orderInfo.name}
              orderSurname={orderInfo.surname}
              orderCompany={orderInfo.company}
              orderCountry={orderInfo.country}
              orderEmail={orderInfo.email}
              nextStep={contactInfo => {
                setOrderInfo(order => ({
                  ...order,
                  ...contactInfo,
                }))
                setViewStep(viewSteps.ORDER_FORM_ADDRESS)
              }}
            />
          )}
          {viewStep === viewSteps.ORDER_FORM_ADDRESS && (
            <FormAddressInfo
              orderAddress1={orderInfo.address1}
              orderAddress2={orderInfo.address2}
              orderZip={orderInfo.zip}
              orderCity={orderInfo.city}
              orderPhoneNumber={orderInfo.phoneNumber}
              nextStep={addressInfo => {
                setOrderInfo(order => ({
                  ...order,
                  ...addressInfo,
                }))
                setViewStep(viewSteps.ORDER_FORM_ADDITIONAL)
              }}
            />
          )}
          {viewStep === viewSteps.ORDER_FORM_ADDITIONAL && (
            <FormAdditionalInfo
              orderAdditionalInfo={orderInfo.additionalInfo}
              nextStep={additionalInfo => {
                setOrderInfo(order => ({
                  ...order,
                  ...additionalInfo,
                }))
                nextStep(orderInfo)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderForm
