import React, { useState } from "react"
import { AddressInfo } from "./orderForm"

type Props = {
  nextStep: (addressInfo: AddressInfo) => void
  orderAddress1: string
  orderAddress2?: string
  orderZip: string
  orderCity: string
  orderPhoneNumber: string
}

const isValid = (addressInfo: AddressInfo) => {
  return (
    addressInfo.address1.length &&
    addressInfo.city.length &&
    addressInfo.phoneNumber.length &&
    addressInfo.zip.length
  )
}

const inputClasses = "rounded-lg bg-white/60 border-none font-sans min-w-0"
const nextButtonClasses =
  "disabled:bg-green/50 text-md mx-auto w-[160px] rounded-full bg-green py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out md:text-[14px] lg:w-[220px]"

const FormAddressInfo: React.FC<Props> = ({
  nextStep,
  orderAddress1,
  orderAddress2,
  orderZip,
  orderCity,
  orderPhoneNumber,
}) => {
  const [address1, setAddress1] = useState(orderAddress1)
  const [address2, setAddress2] = useState(orderAddress2)
  const [zip, setZip] = useState(orderZip)
  const [city, setCity] = useState(orderCity)
  const [phoneNumber, setPhoneNumber] = useState(orderPhoneNumber)

  return (
    <div className="relative flex h-full w-full flex-col gap-4">
      <div className="flex flex-col">
        <span className="font-sans text-[10px] md:text-[12px]">
          Please fill out:{" "}
        </span>
        <span className="text-[14px] uppercase text-green">
          Contact information (2/2)
        </span>
      </div>
      <input
        className={inputClasses}
        type="text"
        defaultValue={address1}
        placeholder="Street, Number"
        onChange={e => setAddress1(e.target.value)}
      />
      <input
        className={inputClasses}
        defaultValue={address2}
        type="text"
        placeholder="Apt. (Optional)"
        onChange={e => setAddress2(e.target.value)}
      />
      <div className="flex flex-row gap-4">
        <input
          className={inputClasses}
          defaultValue={zip}
          type="text"
          placeholder="Zip Code"
          onChange={e => setZip(e.target.value)}
        />
        <input
          className={inputClasses}
          defaultValue={city}
          type="text"
          placeholder="City"
          onChange={e => setCity(e.target.value)}
        />
      </div>
      <input
        className={inputClasses}
        defaultValue={phoneNumber}
        type="text"
        placeholder="Phone Number"
        onChange={e => setPhoneNumber(e.target.value)}
      />

      <button
        className={nextButtonClasses}
        onClick={() => {
          const addressInfo = {
            address1,
            address2,
            zip,
            city,
            phoneNumber,
          }
          if (isValid(addressInfo)) {
            nextStep(addressInfo)
          }
        }}
        disabled={
          !isValid({
            address1,
            address2,
            zip,
            city,
            phoneNumber,
          })
        }
      >
        Next
      </button>
    </div>
  )
}

export default FormAddressInfo
