import React, { useState } from "react"
import { ContactInfo } from "./orderForm"

type Props = {
  nextStep: (contactInfo: ContactInfo) => void
  orderName: string
  orderSurname: string
  orderEmail: string
  orderCountry: string
  orderCompany?: string
}

const isValid = (contactInfo: ContactInfo) => {
  return (
    contactInfo.name.length &&
    contactInfo.surname.length &&
    contactInfo.email.length &&
    contactInfo.country.length
  )
}

const inputClasses = "rounded-lg bg-white/60 border-none font-sans min-w-0"
const nextButtonClasses =
  "disabled:bg-green/50 text-md mx-auto w-[160px] rounded-full bg-green py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out md:text-[14px] lg:w-[220px]"

const FormContactInfo: React.FC<Props> = ({
  nextStep,
  orderName,
  orderSurname,
  orderEmail,
  orderCountry,
  orderCompany,
}) => {
  const [name, setName] = useState(orderName)
  const [surname, setSurname] = useState(orderSurname)
  const [email, setEmail] = useState(orderEmail)
  const [country, setCountry] = useState(orderCountry)
  const [company, setCompany] = useState(orderCompany)

  return (
    <div className="relative flex h-full w-full flex-col gap-4">
      <div className="flex flex-col">
        <span className="font-sans text-[10px] md:text-[12px]">
          Please fill out:{" "}
        </span>
        <span className="text-[14px] uppercase text-green">
          Contact information (1/2)
        </span>
      </div>
      <input
        className={inputClasses}
        defaultValue={email}
        type="text"
        placeholder="E-mail"
        onChange={e => setEmail(e.target.value)}
      />
      <input
        className={inputClasses}
        defaultValue={country}
        type="text"
        placeholder="Country, Region"
        onChange={e => setCountry(e.target.value)}
      />
      <div className="flex flex-row gap-4">
        <input
          className={inputClasses}
          type="text"
          defaultValue={surname}
          placeholder="Surname"
          onChange={e => setSurname(e.target.value)}
        />
        <input
          className={inputClasses}
          type="text"
          defaultValue={name}
          placeholder="Name"
          onChange={e => setName(e.target.value)}
        />
      </div>
      <input
        className={inputClasses}
        type="text"
        defaultValue={company}
        placeholder="Company (Optional)"
        onChange={e => setCompany(e.target.value)}
      />

      <button
        className={nextButtonClasses}
        onClick={() => {
          const contactInfo = {
            name,
            surname,
            email,
            country,
            company,
          }
          if (isValid(contactInfo)) {
            nextStep(contactInfo)
          }
        }}
        disabled={
          !isValid({
            name,
            surname,
            email,
            country,
            company,
          })
        }
      >
        Next
      </button>
    </div>
  )
}

export default FormContactInfo
