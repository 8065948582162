import React, { useEffect } from "react"
import CrossIcon from "../../images/svg/cross.svg"

const nextButtonClasses =
  "text-md mx-auto w-fill rounded-full bg-green py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out md:text-[14px]"

type Props = {
  nmkrUrl: string
  resetSteps: () => void
}

const Confirmation: React.FC<Props> = ({ nmkrUrl, resetSteps }) => {
  useEffect(() => {
    nmkrUrl && window.open(nmkrUrl)
  }, [nmkrUrl])

  return (
    <div className="flex h-full w-full flex-row items-center justify-center">
      <div className="flex flex-col gap-4 md:flex-row ">
        <div className="flex w-fit max-w-[600px] flex-col gap-6 rounded-[10px] bg-white/50 p-4 pr-8 text-left font-gravity-wide text-[10px] backdrop-blur-lg">
          <button
            className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
            onClick={resetSteps}
          >
            <CrossIcon className="h-4 w-4 text-white" />
          </button>

          <div className="flex flex-col uppercase">
            <span className="text-[14px] text-green">Hang Tight</span>
            <span className="font-sans text-[10px] md:text-[12px]">
              You will be taken to nmkr to complete your purchase!
            </span>
          </div>

          <button
            className={nextButtonClasses}
            onClick={() => {
              window.open(nmkrUrl)
            }}
          >
            Take me to Nmkr
          </button>
        </div>
      </div>
    </div>
  )
}

export default Confirmation
